/* eslint-disable prettier/prettier */
export const endpoints = {
  auth: {
    signup: "authentication/signup",
    otpVerification: "authentication/otpVerification",
    signin: "authentication/signin",
    uploadProfileImage: "authentication/uploadProfileImage",
    updateUserName:"authentication/updateProfile",
    getUserProfile:"authentication/getUserProfile"
  },
}
